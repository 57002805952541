/** @jsx jsx */
import { styled, css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N30 } from '@atlaskit/theme/colors';

// WS-2881 - We need to adjust the padding for the buttons to match the editor and shrink the "Add issue" icon
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BuiltInActions = styled.div({
	padding: token('space.025', '2px'),
	display: 'flex',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button, & > button:hover': {
		height: 'inherit',
		display: 'flex',
		alignItems: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > button:last-of-type span[role="presentation"]': {
		height: '20px',
		width: '20px',
	},
});

const disabledButtonWrapperStyle = css({
	position: 'relative',
});

export const DisabledButtonWrapper = (props) => <div css={disabledButtonWrapperStyle} {...props} />;

const disabledButtonOverlayStyle = css({
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '1000',
	backgroundColor: 'transparent',
	cursor: 'not-allowed',
});

export const DisabledButtonOverlay = (props) => <div css={disabledButtonOverlayStyle} {...props} />;

const dividerStyle = css({
	margin: `5px ${token('space.025', '2px')}`,
	width: '1px',
	backgroundColor: token('color.border', N30),
});

export const Divider = (props) => <div css={dividerStyle} {...props} />;
